.profiles {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.pastor-profile {
    margin-top: 16px;
    width: 352px;
    height: 100%;
}

.headshot {
    width: 352px;
}

.profile-text {
    margin-bottom: 40px;
    margin-top: 16px;
}

.profile-text h3 {
    margin-top: 0px;
    margin-bottom: 16px;
}


@media only screen and (max-width: 1260px) {

    .profiles {
        flex-wrap: wrap;
        margin-bottom: -56px;
    }

    .pastor-profile {
        width: auto;
        margin-bottom: 56px;
        max-width: 352px;
    }

    .headshot {
        width: 100%;
    }

}