.sermons {
    border-top: solid 1px #747474;
}

.sermon {
    height: 64px;
    overflow: hidden;
    display: flex;
    border-bottom: solid 1px #747474;
    transition: 100ms ease-in-out;
}

.sermonButtons {
    flex-direction: column;
}

.sermonBtn {
    background-color: #F6F6F6;
    border: none;
    width: 100%;
    height: 100%;
    cursor: pointer;
    height: 24px;
    transition: 200ms;

    margin-bottom: 5px;

    &:hover {
        background-color: #E6E6E6;
    }
}

.play {
    width: 64px;
    height: 64px;
}

.downloadSermon {
    background: #F6F6F6 no-repeat center url(../assets/download.svg);
}

.viewGuide {
    background: #F6F6F6 no-repeat center url(../assets/guide.svg);
}

.sermonInfo {
    height: 64px;
    width: 1056px;
    padding: 0 16px 0 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.sermonInfoRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.sermonAttributes {
    display: flex;
    flex-direction: row;
}

.infoDivider {
    width: 0.5px;
    height: 12px;
    background-color: #000;
    margin: 3px 8px 0 8px;
}

.new-tag {
    width: 64px;
    height: 24px;
    background-color: #F6F6F6;
    font-size: 13px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -3px;
}

.filters-wrapper {
    display: flex;
    margin-bottom: 32px;
    align-items: flex-end;
    justify-content: space-between;
}

.filters {
    display: grid;
    grid-auto-flow: column;
    gap: 16px;
}

.pagination {
    display: grid;
    grid-auto-flow: column;
    margin-top: 32px;
}

.pagination-navigation {
    display: grid;
    grid-auto-flow: column;
    gap: 8px;
    justify-self: center;
}

.page-count {
    position: absolute;
    justify-self: flex-end;
    align-self: center;
    font-style: italic;
    color: #747474;
}

.nav-adjacent,
.nav-skip {
    height: 48px;
    width: 48px;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.nav-skip em {
    font-weight: 400;
}

.page-numbers {
    margin: 0 8px;
}

.page-number {
    height: 48px;
    width: 48px;
    border: none;
    background-color: #f6f6f6;
    cursor: pointer;
    transition: background-color 0.2s;
}

.page-number.active {
    background-color: #D9D9D9;
}

.page-number.active em {
    font-weight: 800;
}

.page-number:hover:not(.active) {
    background-color: #D9D9D9;
}

.series-section {
    text-align: center;
    margin-top: 80px;
}

.series-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 24px;
}

.series-item {
    margin: 0.5rem;
    padding: 4px 8px;
    background: #f6f6f6;
    border-radius: 4px;
    text-decoration: none;
    transition: background-color 0.2s;
}

.series-item:hover {
    background: #ddd;
}


.sermons-description {
    padding: 48px 0;
    border-bottom: solid 1px black;
    margin-bottom: 24px;
}



.featured-series-list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 8px;
}

.featured-series {
    color: #747474;
}

.featured-series-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 352px;
    height: 240px;
    padding: 16px;
    text-decoration: none;
    background-image: linear-gradient(45deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.8) 100%);
}

.featured-series-title {
    background-color: white;
    width: fit-content;
    padding: 4px 8px;
}

.featured-series-date-wrapper {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
}

.featured-series-date {
    color: white;
}

.background-filter {
    width: 352px;
    height: 240px;
    position: absolute;
    margin: -16px;
    z-index: -1;
    background-size: cover;
    background-position: center;
}




@media only screen and (max-width: 1260px) {

    .filters-wrapper {
        display: block;
    }

    .filters {
        margin-top: 32px;
        margin-bottom: -16px;
    }

    .sermonInfo {
        width: auto;
    }

    .featured-series-item {
        width: calc(100vw - 80px);
    }

    .background-filter {
        width: calc(100vw - 80px);
    }

    .page-numbers {
        display: none;
    }

    .page-count {
        justify-self: center;
        margin-top: 72px;
    }

    .featured-series-list{
        gap: 16px;
    }

}

@media only screen and (max-width: 960px) {
    .sermonAttributes {
        display: none;
    }

    .sermonInfo {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .sermonInfoRow {
        width: unset;
    }

    .featured-series-item {
        width: calc(100vw - 40px);
    }

    .background-filter {
        width: calc(100vw - 40px);
    }
}