html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    font-family: sans-serif;
    height: 100%;
    overflow: -moz-scrollbars-vertical;
    overflow-y: scroll;
}

* {
    box-sizing: border-box;
}

::selection {
    background: #f6ffa1;
}

ul {
    list-style-type: none;
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 0;
}

body {
    min-height: 100%;
    color: #000000;
    background-color: #fff;
    margin: 0;
    font-size: 15px;
    font-family: red-hat-display, sans-serif;
    line-height: 125%;
    object-fit: none;
}

h1 {
    font-family: red-hat-display, sans-serif;
    font-size: 48px;
    font-weight: 600;
    line-height: normal;
    margin: 0;
    line-height: 44px;
}

h2 {
    font-family: red-hat-display, sans-serif;
    font-size: 36px;
    font-weight: 600;
    line-height: normal;
    margin: 0;
}

h3 {
    font-family: red-hat-display, sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: normal;
    margin: 0;
}

h4 {
    color: #747474;
    margin-bottom: 8px;
    font-size: 15px;
    font-weight: 400;
    font-family: red-hat-display, sans-serif;
    margin: 2px 0 24px 0;
}

h5 {
    margin: 0px 0px 6px 0px;
    font-size: 15px;
    font-weight: 800;
    font-family: red-hat-display, sans-serif;
}

p {
    margin-top: 0;
    margin-bottom: 0;
    font-family: warnock_proregular, Palatino Linotype, Book Antiqua, Palatino, serif;
    font-size: 16px;
    font-weight: 400;
}

em {
    font-size: 15px;
    font-weight: 600;
    font-family: red-hat-display, sans-serif;
    font-style: normal;
}

a {
    color: #000;
    font-size: 15px;
}

button {
    padding: 0;
}

input {
    background-color: transparent;
    margin: 0;
    padding: 0;
}

small {
    font-size: 13px;
}

strong {
    font-weight: 600;
}

select {
    height: 24px;
    background-color: #F6F6F6;
    border: none;
    border-radius: 5px;
    font-family: red-hat-display, sans-serif;
    font-weight: 600;
    font-size: 16px;
    padding: 0 4px 0 4px;
}

mark {
    background-color: white;
    padding: 0 16px 0 16px;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
}

.container-wide {
    max-width: 1376px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
}

.container-medium {
    max-width: 1200px;
    padding: 0 40px 0 40px;
    margin: auto;
}

.header {
    height: 280px;
    background-size: cover;
    margin-bottom: 64px;
}

.header-short {
    height: 96px;
    background-size: cover;
    margin-bottom: 64px;
}

.header-blur {
    height: 100%;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px) brightness(60%);
}

.header-title {
    padding-top: 192px;
    color: white;
}

.row {
    display: flex;
    flex-direction: row;
}

.loading-text{
    font-weight: 400;
    position: absolute;
    left: 50%;
    translate: -50% 50px;
}


@media only screen and (max-width: 1260px) {

    h5 {
        margin: 0;
    }

}

@media only screen and (max-width: 960px) {

    .container-medium {
        padding: 0 20px 0 20px;
    }

}